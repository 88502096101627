import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#000"></circle>
      <path
        stroke="#fff"
        strokeWidth="1.286"
        d="M15.473 17.849l4.31-2.262m-4.31 2.262v4.724m0-4.724l-1.984-1.198m6.295-1.064l4.379 2.262m-4.38-2.262v-2.662m4.38 4.924v4.724m0-4.724l2.052-1.198m-2.052 5.922l-4.38 2.195m4.38-2.195l2.052.998m-6.431 1.197l-4.31-2.195m4.31 2.195v2.529m-4.31-4.724l-1.985.998M10 23.42v1.96l1.745.919 1.813-.92v-1.96l-1.813-.98-1.745.98zm0-8.517v1.96l1.745.92 1.813-.92v-1.96l-1.813-.98-1.745.98zm8.073-4.923v1.96l1.746.919 1.812-.919V9.98L19.82 9l-1.746.98zm0 18.363v1.96l1.746.92 1.812-.92v-1.96l-1.812-.98-1.746.98zm8.21-4.923v1.96l1.746.919 1.812-.92v-1.96l-1.812-.98-1.745.98zm0-8.517v1.96l1.746.92 1.812-.92v-1.96l-1.812-.98-1.745.98z"
      ></path>
    </svg>
  )
}

export default Icon
