import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#000"></circle>
      <path stroke="#000" d="M10.5 15v9"></path>
      <path
        fill="#fff"
        d="M29.955 15.494a.833.833 0 00-1.057-.52.47.47 0 01-.484-.113l-3.276-3.278a.47.47 0 01-.112-.483.834.834 0 10-1.577-.536 7.447 7.447 0 00.204 5.344 5.782 5.782 0 01.25 3.771.209.209 0 01-.349.094L20.23 16.45a.208.208 0 01.093-.349 5.923 5.923 0 011.427-.205.834.834 0 00-.017-1.667h-.017a7.712 7.712 0 00-5.25 2.235 7.594 7.594 0 00-1.65 8.283c.146.341.26.696.338 1.059a.209.209 0 01-.351.191l-1.366-1.366a.208.208 0 01-.024-.266.832.832 0 00-.681-1.312H12.7a7.438 7.438 0 00-2.136.394.833.833 0 00.537 1.577.47.47 0 01.484.113l3.276 3.279a.469.469 0 01.112.483.833.833 0 101.577.538 7.448 7.448 0 00-.204-5.344 5.781 5.781 0 01-.25-3.77.209.209 0 01.349-.094l3.321 3.322a.208.208 0 01-.092.348 5.921 5.921 0 01-1.427.206.833.833 0 00.018 1.666h.017a7.712 7.712 0 005.25-2.234 7.595 7.595 0 001.65-8.284 5.813 5.813 0 01-.339-1.058.209.209 0 01.352-.192l1.365 1.366a.208.208 0 01.023.267.832.832 0 00.682 1.311h.03a7.437 7.437 0 002.137-.393.833.833 0 00.522-1.06zm-7.174 6.39a5.826 5.826 0 01-.898.897.416.416 0 01-.555-.03l-4.078-4.08a.417.417 0 01-.03-.555 5.826 5.826 0 01.898-.897.416.416 0 01.555.03l4.077 4.08a.417.417 0 01.03.555z"
      ></path>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M10 10H30V30H10z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon
