import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#000"></circle>
      <path
        fill="#fff"
        d="M16.952 25.529l5.794-5.795-5.794-5.794-1.579 1.566 3.078 3.078h-8.297v2.3h8.297l-3.078 3.072 1.579 1.573zM29.237 9.43H26.35v20.608h2.888V9.43z"
      ></path>
    </svg>
  )
}

export default Icon
