import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#000"></circle>
      <path
        fill="#fff"
        d="M27.917 14.167a2.083 2.083 0 10-2.084-2.084c0 .217.036.433.105.639a.21.21 0 01-.05.212l-2.221 2.227a.208.208 0 01-.256.03 4.143 4.143 0 00-5.467 1.034.207.207 0 01-.25.063l-3.466-1.609a.107.107 0 01-.061-.096 2.083 2.083 0 10-2.084 2.084c.457-.002.9-.155 1.26-.436a.21.21 0 01.215-.024l3.47 1.61a.21.21 0 01.117.226c-.175.992.02 2.015.55 2.874a.208.208 0 01-.032.255l-4.728 4.715a.209.209 0 01-.213.05 2.03 2.03 0 00-.639-.104 2.083 2.083 0 102.084 2.084 2.04 2.04 0 00-.104-.638.208.208 0 01.05-.213l4.734-4.721a.209.209 0 01.255-.03 4.14 4.14 0 001.155.482.209.209 0 01.159.202v2.895a.183.183 0 01-.1.167 2.083 2.083 0 101.88.006.208.208 0 01-.114-.185V23a.208.208 0 01.159-.203 4.15 4.15 0 002.022-1.197.209.209 0 01.243-.046l1.23.591a.21.21 0 01.118.167 2.076 2.076 0 10.674-1.777.207.207 0 01-.229.032l-.945-.452a.21.21 0 01-.11-.25c.11-.361.169-.737.173-1.115 0-.76-.21-1.504-.605-2.152a.208.208 0 01.03-.255l2.225-2.23a.209.209 0 01.212-.051c.206.069.42.104.638.105z"
      ></path>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M10 10H30V30H10z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon
