import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#000"></circle>
      <path
        fill="#fff"
        d="M26.037 16.725a.416.416 0 00-.37-.225H19.52l1.553-6.995a.417.417 0 00-.745-.333l-8.334 11.666a.417.417 0 00.34.662h6.146l-1.553 6.992a.416.416 0 00.745.333l8.334-11.667a.416.416 0 00.03-.433z"
      ></path>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M9 9H29V29H9z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon
