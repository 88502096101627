import React, { useEffect } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import SEO from '../components/seo'
import HeroForm from '../components/heroForm'
import Footer from '../components/footer'
import SectionsWithIcons from '../components/sectionsWithIcons'
import HeartbeatSignupForm from '../components/heartbeatSignupForm'
import HeaderText from '../components/headerText'
import SubText from '../components/subText'
import Box from '../components/box'
import TabPicker from '../components/tabPicker'
import Nav from '../components/nav'
import checkUtmParams from '../utils/checkUtmParams'

import ProductTeams from '../icons/productTeams'
import ProductTopics from '../icons/productTopics'
import ProductProjects from '../icons/productProjects'
import WhyAlignment from '../icons/whyAlignment'
import WhyAsync from '../icons/whyAsync'
import WhyLeadership from '../icons/whyLeadership'
import WhyCulture from '../icons/whyCulture'
import WhyContext from '../icons/whyContext'
import WhyProductivity from '../icons/whyProductivity'
import WhyMotivations from '../icons/whyMotivations'
import WhyDiscussion from '../icons/whyDiscussions'
import WhyAccountability from '../icons/whyAccountability'
import PulseFeaturesForCompany from '../assets/svg/pulse-feature-company.svg'
import useHotjarScript from '../utils/hooks/useHotjarScript'

const Why = () => {
  const data = useStaticQuery(graphql`
    query {
      cycleAlign: file(relativePath: { eq: "cycle-align.png" }) {
        childImageSharp {
          fixed(width: 370, height: 700, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cyclistTwo: file(relativePath: { eq: "cyclist-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1130, maxHeight: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oakley: file(relativePath: { eq: "oakley.png" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 80, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pulseModal: file(relativePath: { eq: "pulse-modal.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pulseNudges: file(relativePath: { eq: "pulse-nudges.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pulseStreams: file(relativePath: { eq: "pulse-streams.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    checkUtmParams()
  }, [])

  useHotjarScript()

  return (
    <>
      <SEO
        title="Why Pulse - Experience an unprecedent level of alignment"
        description="Experience a peloton-like level of alignment. One direction to follow, one goal to pursue. One pace to keep. Your remote team at full gas. "
      />
      <Nav />
      <HeroContainer>
        <HeroForm
          headerText="EXPERIENCE AN Unprecedented LEVEL OF team ALIGNMENT."
          subText="Elevate your remote team to a peloton-like level of alignment. One direction to follow. One goal to pursue. One pace to keep – the fastest possible."
        />
        <HeroImage>
          <Img fixed={data.cycleAlign.childImageSharp.fixed} fadeIn={false} />
        </HeroImage>
      </HeroContainer>
      <Box
        width="43.75rem"
        maxWidth="97%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        margin="auto"
        marginTop="7rem"
        marginBottom="3.75rem"
      >
        <HeaderText as="h3">
          chat alone can’t <br /> keep you in sync.
        </HeaderText>
        <SubText as="p">
          Pulse is a stripped-back communication product engineered to keep your
          team on the same page. We’ve specifically designed it for those teams
          who want to be the best at what they do.
        </SubText>
      </Box>
      <Box width="90%" margin="auto" paddingBottom="8.125rem" maxWidth="1200px">
        <TabPicker
          options={[
            {
              icon: <ProductTeams />,
              title: 'Space to Say more',
              description:
                'Rigor of thought at its best. Pulse has been designed for long-form updates to make you brilliant at communication. Convey what matters with absolute clarity so that your team will work faster, and with more confidence',
              component: (
                <Box width="700px" height="auto">
                  <Img
                    fluid={data.pulseModal.childImageSharp.fluid}
                    fadeIn={false}
                  />
                </Box>
              ),
            },
            {
              icon: <ProductTopics />,
              title: 'Disciplined Communication',
              description:
                'Updates rigorously organized and chronologically ordered. When you send a pulse, just choose a stream – for example, @product-updates – and everyone who’s following it will read what you have to say.',
              component: (
                <Box width="700px" height="auto">
                  <Img
                    fluid={data.pulseStreams.childImageSharp.fluid}
                    fadeIn={false}
                  />
                </Box>
              ),
            },
            {
              icon: <ProductProjects />,
              title: 'Instant feedback loop',
              description:
                "Is anyone paying attention? Pulse helps you understand how people read, skim or interact with your message. Use it to always stay informed, get in touch at the right time, and nudge people as soon as they're out of the loop.",
              component: (
                <Box width="700px" height="auto">
                  <Img
                    fluid={data.pulseNudges.childImageSharp.fluid}
                    fadeIn={false}
                  />
                </Box>
              ),
            },
          ]}
        />
      </Box>
      <Box
        marginTop="8.125rem"
        width="51.5625rem"
        maxWidth="97.5%"
        margin="auto"
        textAlign="center"
      >
        <HeaderText as="h3">
          all the ways pulse can <br /> transform your organization.
        </HeaderText>
        <SubText as="p">
          Pulse helps you bring the operational excellence of elite athletes
          teams to your remote workforce, so that people can do a better job,
          have a stronger sense of purpose while doing it, and accomplish
          whatever their misson requires them to do.
        </SubText>
      </Box>
      <Box
        display="flex"
        width="100%"
        justifyContent="flex-end"
        marginTop="4.375rem"
        marginBottom="3.75rem"
        alignItems="center"
      >
        <Box width="80px" height="100%" marginRight="20px">
          <Img fluid={data.oakley.childImageSharp.fluid} fadeIn={false} />
        </Box>
        <PulseFeaturesForCompany />
      </Box>
      <Box marginBottom="3.125rem">
        <SectionsWithIcons
          sections={[
            {
              icon: <WhyAlignment />,
              header: 'Faster Team Alignment',
              description:
                'Inter and intra-team alignment at their best. Seamlessly streamline updates to groups, teams, units, or even entire departments.',
              // link: 'See Use Cases',
            },
            {
              icon: <WhyLeadership />,
              header: 'Brilliant Leadership Comms',
              description:
                'Embed. Enrich. Enhance. Pro tools for Pro leaders to maximize their ability to inspire, motivate and electrify their people.',
              // link: 'See Use Cases',
            },
            {
              icon: <WhyAsync />,
              header: 'Flawless asynchronicity',
              description:
                'Don’t preach it. Practice asynchronous communication and write in a more inclusive, timezone-aware and ultimately effective way.',
              // link: 'See Use Cases',
            },
          ]}
        />
      </Box>
      <Box marginBottom="3.125rem">
        <SectionsWithIcons
          sections={[
            {
              icon: <WhyCulture />,
              header: 'Redefined Written Culture',
              description:
                'Pivot to a written culture to foster exchanges of complete thoughts and stop one-line-at-a-time jousts in chaotic group chats.',
              // link: 'See Use Cases',
            },
            {
              icon: <WhyContext />,
              header: 'Perfect Shared Context',
              description:
                'The centralized space your teams use to actually know what’s going on and absorb context on other parts of your organization.',
              // link: 'See Use Cases',
            },
            {
              icon: <WhyProductivity />,
              header: 'Incredible Productivity',
              description:
                'Incentivize people to log out from chat,  spend more time in flow and get more done. They can rest assured because if it’s not on Pulse, it never happened.',
              // link: 'See Use Cases',
            },
          ]}
        />
      </Box>
      <Box marginBottom="8.125rem">
        <SectionsWithIcons
          sections={[
            {
              icon: <WhyMotivations />,
              header: 'Higher Motivations',
              description:
                'If chat is where you discuss the What, Pulse is where you discuss the Why – that which actually creates an impression in people’s mind.',
              // link: 'See Use Cases',
            },
            {
              icon: <WhyDiscussion />,
              header: 'Powerful Discussions',
              description:
                'Self-paced long-form conversations to discuss, decide and resolve. Now go ahead, cancel that superfluous weekly meeting.',
              // link: 'See Use Cases',
            },
            {
              icon: <WhyAccountability />,
              header: 'Faultless Accountability',
              description:
                "People bound to their words, whether recorded in yesterday's stand-up, last week's check-in, or last month's retro.",
              // link: 'See Use Cases',
            },
          ]}
        />
      </Box>
      <Box width="100%" marginBottom="10rem">
        <BackgroundImage
          fluid={data.cyclistTwo.childImageSharp.fluid}
          backgroundColor="black"
          style={{
            height: '25rem',
            display: 'flex',
          }}
        >
          <Box
            margin="auto"
            width="51.5625rem"
            maxWidth="97.5%"
            textAlign="center"
            display="flex"
            flexDirection="column"
          >
            <HeaderText style={{ color: '#ffffff' }} as="h3">
              alignment and COHESIVE thinking are what enable humans to excel.
            </HeaderText>
            <SubText style={{ color: '#ffffff' }} as="p">
              Whether you’re fighting against time, air resistance, mental
              pressure or physical fatigue, deep focus and strong alignment are
              what enable teams of every kind to stay in control and achieve
              otherwise impossible goals.
            </SubText>
          </Box>
        </BackgroundImage>
      </Box>
      <HeartbeatSignupForm />
      <Footer />
    </>
  )
}
const HeroContainer = styled.div`
  display: flex;
  max-width: 75rem;
  min-height: 100%;
  height: 43.75rem;
  margin: auto;
  overflow: hidden;

  @media (min-width: 1113px) {
    margin: auto;
  }
`

const HeroImage = styled.div`
  display: none;

  @media ${props => props.theme.device.laptop} {
    display: block;
    width: 23.125;
  }
`

export default Why
