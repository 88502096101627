import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#000"></circle>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M10.834 13.333v5h5M29.166 26.667v-5h-5"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M27.075 17.5A7.5 7.5 0 0014.7 14.7l-3.866 3.633m18.333 3.334L25.3 25.3a7.501 7.501 0 01-12.375-2.8"
      ></path>
    </svg>
  )
}

export default Icon
