import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#000"></circle>
      <path
        fill="#fff"
        d="M22.4 14l-.4-2h-9v17h2v-7h5.6l.4 2h7V14h-5.6z"
      ></path>
    </svg>
  )
}

export default Icon
