import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#000"></circle>
      <path
        stroke="#fff"
        strokeWidth="1.479"
        d="M1.281 0H16.072V14.791H1.281z"
        transform="matrix(.86603 -.5 .86603 .5 6.172 24.36)"
      ></path>
      <path
        stroke="#fff"
        strokeWidth="1.479"
        d="M1.281 0H16.072V14.791H1.281z"
        transform="matrix(.86603 -.5 .86603 .5 6.172 20.663)"
      ></path>
      <path
        stroke="#fff"
        strokeWidth="1.479"
        d="M1.281 0H16.072V14.791H1.281z"
        transform="matrix(.86603 -.5 .86603 .5 6.172 16.776)"
      ></path>
    </svg>
  )
}

export default Icon
