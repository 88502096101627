import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#000"></circle>
      <path
        fill="#fff"
        d="M28.333 11.25H11.667a1.25 1.25 0 00-1.25 1.25v11.293A1.216 1.216 0 0011.666 25h2.5v3.333a.417.417 0 00.654.343L20.13 25h8.203a1.25 1.25 0 001.25-1.25V12.5a1.25 1.25 0 00-1.25-1.25zm-2.083 6.667a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm-12.5 0a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zm5 0a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0z"
      ></path>
    </svg>
  )
}

export default Icon
