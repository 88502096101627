import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#000"></circle>
      <path
        fill="#fff"
        d="M5.938 23.469L5 22.844 24.844 13l1.093.625-20 9.844zM10.469 25.813l-.938-.625 19.844-9.844 1.094.625-20 9.844zM15.156 28l-.937-.625 19.843-9.844 1.094.625-20 9.844z"
      ></path>
      <path
        fill="#fff"
        d="M5.938 17.688L5 18.155 25 28l.938-.625-20-9.688zM10.781 15.344l-.937.469 20 9.843.937-.625-20-9.687zM15.469 13.156l-.938.469 20 9.844.938-.625-20-9.688z"
      ></path>
      <path
        stroke="#fff"
        strokeWidth="0.625"
        d="M5.938 23.469L5 22.844 24.844 13l1.093.625-20 9.844zM10.469 25.813l-.938-.625 19.844-9.844 1.094.625-20 9.844zM15.156 28l-.937-.625 19.843-9.844 1.094.625-20 9.844z"
      ></path>
      <path
        stroke="#fff"
        strokeWidth="0.625"
        d="M5.938 17.688L5 18.155 25 28l.938-.625-20-9.688zM10.781 15.344l-.937.469 20 9.843.937-.625-20-9.687zM15.469 13.156l-.938.469 20 9.844.938-.625-20-9.688z"
      ></path>
    </svg>
  )
}

export default Icon
