import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#000"></circle>
      <mask width="40" height="40" x="0" y="0" maskUnits="userSpaceOnUse">
        <circle cx="20" cy="20" r="20" fill="#C4C4C4"></circle>
      </mask>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M25 22.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm0 0v-5.833A1.666 1.666 0 0023.333 15h-2.5M15 17.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0 0v10"
      ></path>
    </svg>
  )
}

export default Icon
